import { JSXFactory, CustomElementHandler, exportedSiteData, PageContent, render, sitePix } from '@appzuka/rxnano';
import { SiteData } from 'site/siteContent';
import { completeCallback } from '@appzuka/rxnano';
import { SectorBanner } from 'site/components/sectorBanner';
import { brochures } from 'site/components/brochureLinks';

import { accreditations } from 'site/components/accreditations';
import { parallaxBanner } from 'site/components/parallaxBanner';
import { sitePreview } from 'site/components/sitePreview';
import { newsSummary } from 'site/components/newsSummary';

import { excomNews } from '../about/news';

import './home.scss';

const greenPark = () => {
  render(
    <div class='featured-project'>
      <h2>Featured Project - Green Park Station</h2>
      <iframe width="560" height="315" src="https://www.youtube.com/embed/xuZuhGyzKSs?si=xRoOc43mKm4xH4UZ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      <div>
<p>Procom is proud to have participated in the new £20 million Green Park Station on the Reading to Basingstoke line in south Reading.</p>
<p>The station, being delivered by Reading Borough Council, Network Rail and Great Western Railway (GWR), features two 150m platforms, a new fully accessible station building and a fully accessible overbridge, providing access to both platforms via stairs and lifts.</p>
      </div>

    </div>
  )
}
const inspectionBase = () => {
  render(
    <div class='inspection-base'>
      <h2>Procom partner with InventoryBase</h2>
      { () => sitePix(exportedSiteData.assetBase, 'site/inventorybase') }
      <div>
      <p>Procom have partnered with <a href="https://inventorybase.co.uk" target='_blank' rel='noopener noreferrer'>InventoryBase</a>, the UK's leading platform for property reporting, to develop a bespoke App.</p>
    <p>Our app enables clients to streamline property operations for inventory, inspections, fire and legionella risk assessments with video and photographic evidence.</p>
      </div>

    </div>
  )
}

const homeContent = (pco) => {
    <render>
        <div class="margin-container">
            { homeMission }
            { excomNews }
            { newsSummary }
            { sitePreview }
            {/* { inspectionBase } */}
            { greenPark }
        </div>
        { () => parallaxBanner(pco, 'To date we have audited in excess of £2.5 billion in actual costs and have saved millions for our clients') }
        <div class="margin-container">
            { accreditations }
            { brochures }
        </div>
    </render>
}

const homeMission = () => {
    <render>
        <div class="mission">
            <h2>Our Mission</h2>
            <p>To deliver effective management and best value estate and construction solutions always with the aim of exceeding our client's expectations and upholding our commitment to fairness, honesty and continuous improvement.</p>
        </div>
    </render>
}

const bannerTitle = (sector, tagline) => {
  return (
    <render>
      <div class="sector-banner-title-wrapper">
        <div class="sector-banner-title-wrapper__margin-wrapper">
          <div class="sector-banner-title-heading-wrapper">
            <h2>{sector}</h2>
          </div>
          <p>{tagline}</p>
          <div class="sector-divider"></div>
        </div>
      </div>
    </render>
  );
}

const homePages : PageContent = {
    content: [
        {
            pageLocation: '',
            image: () => render(<>
              { () => SectorBanner(`banners/londonhome`, () => bannerTitle('Welcome to Procom', 'Specialising in construction procurement, commercial and project management and forensic cost auditing, Procom has earned recognition for its professional approach serving all clients with a good quality service secured by a combination of qualifications, expertise and experience'), undefined, undefined) }
            </>),
            pageTitle: 'Home',
            content: (pco) => {
                homeContent(pco);
                completeCallback('home');
            },
            name: `home`
        }
    ],
    subMenu: []
};

export {
    homePages
};

